/* eslint-disable import/no-cycle */
import { authenticationConstants } from '../constants'
import { authenticationService } from '../services'

const request = (user, loggedIn) => ({
  type: authenticationConstants.LOGIN_REQUEST,
  payload: { user, loggedIn },
})
const success = (user, loggedIn) => ({
  type: authenticationConstants.LOGIN_SUCCESS,
  payload: { user, loggedIn },
})
const failure = () => ({ type: authenticationConstants.LOGIN_FAILURE })
const logout = () => {
  authenticationService.logout()
  return { type: authenticationConstants.LOGOUT }
}
const map = (zoom, bounds) => ({
  type: authenticationConstants.LOGIN_MAP,
  payload: { zoom, bounds },
})
export const authenticationActions = {
  request,
  success,
  failure,
  logout,
  map,
}
