import { settingsConstants } from '../constants/redux/settings.constants'
import { setProps } from '../helpers/system/object.helpers'
const initialState = {
  isLoading: false,
  isError: false,
  mapSettings: { tooltip: false, cluster: true },
  settings: {},
  router: [],
}
const settings = (state = initialState, action) => {
  switch (action.type) {
    case settingsConstants.INIT:
      return {
        ...state,
        isLoading: true,
      }
    case settingsConstants.ADD:
      return {
        ...state,
        settings: action.payload.settings,
        treeView: action.payload.settings,
        router: action.payload.router,
        isLoading: false,
      }

    case settingsConstants.UPDATE:
      return {
        ...state,
        settings: action.payload.settings,
        router: action.payload.router,
        isLoading: false,
      }
    case settingsConstants.UPDATE_MAP:
      return {
        ...state,
        mapSettings: setProps(
          state.mapSettings,
          action.payload.label,
          action.payload.value,
        ),
      }

    case settingsConstants.FAILURE:
      return {
        ...state,
        isError: true,
        isLoading: false,
      }
    case settingsConstants.CLEAR:
      return {}
    default:
      return state
  }
}

export { settings }
