/* eslint-disable import/no-cycle */
import {
  fleetsActions,
  gatewaysActions,
  jiminiAlertsActions,
  routerActions,
  socketActions,
  tripsActions,
  authenticationActions,
  vehiclesActions,
  alertActions,
} from '../../actions'
import { settingsActions } from '../../actions/settings.actions'
import { meService } from '../../services/users/me.service'
import { closeSocket } from '../socket/socket.helpers'
import { getAllSettings, getRouter } from '../settings/settings.helpers'

const logOut = async (dispatch, socket) => {
  if (socket.status && socket.socket) {
    if (socket.subscribe) {
      dispatch(socketActions.close(socket.socket))
    }
    closeSocket(socket)
  }
  dispatch(authenticationActions.logout())
  dispatch(gatewaysActions.clear())
  dispatch(fleetsActions.clear())
  dispatch(tripsActions.clear())
  dispatch(vehiclesActions.clear())
  dispatch(routerActions.clear())
  dispatch(jiminiAlertsActions.clear())
}

const setHomePage = async (settings, t) => {
  const router = getRouter(settings)
  if (router && router.length > 0) {
    let homeRouter = t('router.home')
    let i = true
    router.forEach((item) => {
      if (i && item.enabled) {
        homeRouter = item.route
        i = false
      }
    })
    localStorage.setItem('home', t(homeRouter))
  }
}
const getSettings = async (user, dispatch, t) => {
  try {
    if (user && user.type) {
      const settings = getAllSettings(user, t)
      if (user.type === 'Vehicle') {
        dispatch(settingsActions.add(settings))
        await setHomePage(settings, t)
        return
      }
      const res = await meService.getUiSettings()
      if (!res) {
        if (user && user.type) {
          dispatch(settingsActions.add(settings))
          await setHomePage(settings, t)
          return
        }
      }
      if (res && res.status === 204) {
        if (user && user.type) {
          dispatch(settingsActions.add(settings))
          await setHomePage(settings, t)
        }

        return
      }
      if (res && res.status === 200) {
        dispatch(settingsActions.add(res.content.data))
        await setHomePage(res.content.data, t)
        return
      }
      if (user && user.type) {
        dispatch(settingsActions.add(settings))
        await setHomePage(settings, t)
        return
      }
      dispatch(alertActions.error(res.content.message, 6000, true))
    }
  } catch (e) {
    dispatch(alertActions.error(e.toString(), 6000, true))
  }
}

export { logOut, getSettings, setHomePage }
