import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'

// eslint-disable-next-line import/no-cycle
import {
  vehicles,
  authentication,
  fleets,
  gateways,
  alert,
  trips,
  drawer,
  drawerNavigation,
  socket,
  jiminiAlerts,
  router,
  settings,
  extractData,
  vehiclesIncidents,
  users,
} from '../../reducers'

const persistenceConfigs = {
  key: 'root',
  storage,
  whitelist: ['settings'],
}

const rootReducer = combineReducers({
  vehicles,
  authentication,
  gateways,
  fleets,
  trips,
  alert,
  drawer,
  drawerNavigation,
  socket,
  jiminiAlerts,
  router,
  settings,
  extractData,
  vehiclesIncidents,
  users,
})
const middlewares = []

if (process.env.NODE_ENV === `development`) {
  // eslint-disable-next-line global-require
  const { logger } = require(`redux-logger`)

  middlewares.push(logger)
}
const persistedReducer = persistReducer(persistenceConfigs, rootReducer)
const store = createStore(
  persistedReducer,
  applyMiddleware(thunkMiddleware, ...middlewares),
)
const persistor = persistStore(store)

export { persistor, store }
