/* eslint-disable import/no-cycle */
import { authenticationConstants } from '../constants'
import { localStorageService } from '../services'
const user = localStorageService.get('user')
const initialState = user
  ? { loggedIn: true, user, map: { zoom: null, bounds: null } }
  : {}

export function authentication(state = initialState, action) {
  switch (action.type) {
    case authenticationConstants.LOGIN_REQUEST:
      return {
        loggingIn: action.payload.loggedIn,
        user: action.payload.user,
      }
    case authenticationConstants.LOGIN_SUCCESS:
      return {
        loggedIn: action.payload.loggedIn,
        user: action.payload.user,
      }
    case authenticationConstants.LOGIN_MAP:
      return {
        ...state,
        map: {
          zoom: action.payload.zoom,
          bounds: action.payload.bounds,
        },
      }
    case authenticationConstants.LOGIN_FAILURE:
      return {}
    case authenticationConstants.LOGOUT:
      return {}
    default:
      return state
  }
}
